import * as React from 'react';
import { useState, useCallback, useContext } from 'react';

import fetch from 'logic/rest/FetchProvider';
import { getPathnameWithoutId, qualifyUrl } from 'util/URLUtils';
import { DATA_WAREHOUSE_API_ROUTES } from 'data-warehouse/Constants';
import UserNotification from 'util/UserNotification';
import { Button, MenuItem } from 'components/bootstrap';
import DataWarehouseActionModalForm from 'data-warehouse/components/DataWarehouseActionModalForm';
import useUserDateTime from 'hooks/useUserDateTime';
import { getDataWarehouseUriParams } from 'data-warehouse/logic/Utils';
import useLocation from 'routing/useLocation';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import StreamsContext from 'contexts/StreamsContext';

import type { DataWarehouse, DataWarehouseActionValues } from '../../Types';

type Props = {
  descriptor: string,
  handleFailures: (failures: Array<{
    entity_id: string
  }>, actionPastTense: string, archiveId: string) => void,
  onSelect?: () => void,
  refetchArchives: () => void,
  archive: DataWarehouse,
  buttonType?: 'menu' | 'button',
  onDelete?: () => void
};

const DeleteArchiveAction = ({
  archive,
  handleFailures,
  refetchArchives,
  descriptor,
  onSelect = () => {},
  buttonType = 'menu',
  onDelete = () => {},
}: Props) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { userTimezone } = useUserDateTime();
  const { pathname } = useLocation();
  const sendTelemetry = useSendTelemetry();
  const streams = useContext(StreamsContext);
  const availableStreams = streams.map((stream) => ({
    key: stream.title,
    value: stream.id,
  }));
  const onDeleteSubmit = useCallback((values: DataWarehouseActionValues) => {
    const uriParams = getDataWarehouseUriParams(values, userTimezone);

    fetch(
      'POST',
      qualifyUrl(`${DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.bulk_delete().url}?${uriParams}`),
      { stream_ids: [archive.stream_id], inclusion_type: values.inclusion_type },
    ).then((response) => {
      const failures = response?.failures || [];
      handleFailures(failures, 'submitted', archive.id);
    }).catch((error) => {
      UserNotification.error(`An error occurred while deleting archive. ${error}`);
    }).finally(() => {
      if (typeof onDelete === 'function') {
        onDelete();
      }

      setShowConfirmDelete(false);
      refetchArchives();
    });
  }, [userTimezone, archive, handleFailures, onDelete, refetchArchives]);

  const onOpenDeleteConfirm = () => {
    if (typeof onSelect === 'function') {
      onSelect();
    }

    sendTelemetry(TELEMETRY_EVENT_TYPE.DATAWAREHOUSE.DATA_DELETION_OPENED, {
      app_pathname: getPathnameWithoutId(pathname),
    });

    setShowConfirmDelete(true);
  };

  const ItemComponent = buttonType === 'menu' ? MenuItem : Button;

  return (
    <>
      <ItemComponent onClick={() => onOpenDeleteConfirm()} bsSize="xsmall">
        Delete
      </ItemComponent>
      {showConfirmDelete && (
        <DataWarehouseActionModalForm modalTitle="Deleting Datawarehouse data! Are you sure?"
                                      show={showConfirmDelete}
                                      type="delete"
                                      onHide={() => setShowConfirmDelete(false)}
                                      submitButtonText="Delete"
                                      hourOnly
                                      availableStreams={availableStreams}
                                      streamIds={[archive.stream_id]}
                                      onSubmit={onDeleteSubmit}>
          <p>{`You are about to delete data from 1 ${descriptor}. This action cannot be undone.`}</p>
        </DataWarehouseActionModalForm>
      )}
    </>
  );
};

export default DeleteArchiveAction;
