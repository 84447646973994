import * as React from 'react';
import { useContext, useRef, useMemo } from 'react';
import * as Immutable from 'immutable';

import type CancellablePromise from 'logic/rest/CancellablePromise';
import type { BackendMessage } from 'views/components/messagelist/Types';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import type { After, OnLoadMessages } from 'logview/types';

import { loadPrevPage, loadNextPage } from './LogViewDataFetching';
import ListStateContext from './ListStateContext';
import ScrollPositionContext from './ScrollPositionContext';
import type { LogViewState } from './LogViewStateProvider';

import type { PageRefs, TableRef } from '../LogViewWidget';

export const orderMessages = (messages) => [...messages].reverse();

type InternalListState = LogViewState['listState'];

const _getVisiblePagesWithMessages = (visiblePagesIds: InternalListState['visiblePagesIds'], loadedPages: InternalListState['loadedPages']) => {
  let visiblePages = Immutable.OrderedSet<[number, Array<BackendMessage>]>();

  visiblePagesIds.sort((pageIdA, pageIdB) => pageIdB - pageIdA).forEach((pageId) => {
    visiblePages = visiblePages.add([pageId, loadedPages[pageId]]);
  });

  return visiblePages;
};

type Props = {
  children: React.ReactNode,
  pageRefs: PageRefs,
  after: After,
  effectiveTimerange: AbsoluteTimeRange,
  total: number,
  tableRef: TableRef,
  listState: InternalListState,
  updateLogViewState: (logViewState: Omit<LogViewState, 'status'>) => void,
  onLoadMessages: OnLoadMessages,
}

const ListStateProvider = ({
  children,
  pageRefs,
  after,
  effectiveTimerange,
  total,
  listState,
  tableRef,
  updateLogViewState,
  onLoadMessages,
}: Props) => {
  const {
    finishedScrollPositionUpdateRef,
    setFinishedScrollPositionUpdate,
    setLastPosition: setLastScrollPosition,
  } = useContext(ScrollPositionContext);
  const pages = _getVisiblePagesWithMessages(listState.visiblePagesIds, listState.loadedPages);
  const loadedAllPrevMessages = listState.loadedMessagesCount === total || !after;
  const loadPrevPromiseRef = useRef<CancellablePromise<void>>(undefined);

  const listStateContextValue = useMemo(() => {
    const updateListState = (
      newInternalListState: InternalListState,
      scrollPositionUpdate: LogViewState['scrollPositionUpdate'],
    ) => {
      setFinishedScrollPositionUpdate(false);
      setLastScrollPosition(tableRef.current.scrollTop);
      updateLogViewState({ listState: newInternalListState, scrollPositionUpdate });
    };

    const _loadPrevPage = () => loadPrevPage({
      effectiveTimerange,
      finishedScrollPositionUpdateRef,
      internalListState: listState,
      loadedAllPrevMessages,
      loadPrevPromiseRef,
      pageRefs,
      updateListState,
      onLoadMessages,
    });

    const _loadNextPage = () => loadNextPage({
      loadedAllPrevMessages,
      internalListState: listState,
      pageRefs,
      updateListState,
      finishedScrollPositionUpdateRef,
    });

    const _cancelLoadPrevPage = () => {
      if (!!loadPrevPromiseRef.current && pages.size > 1) {
        loadPrevPromiseRef.current.cancel();
        // eslint-disable-next-line no-param-reassign
        loadPrevPromiseRef.current = undefined;
      }
    };

    return ({
      actions: {
        cancelLoadPrevPage: _cancelLoadPrevPage,
        loadNextPage: _loadNextPage,
        loadPrevPage: _loadPrevPage,
      },
      bottomPageId: listState.visiblePagesIds.min(),
      loadedAllPrevMessages,
      pages,
    });
  }, [
    listState,
    loadedAllPrevMessages,
    pages,
    setFinishedScrollPositionUpdate,
    setLastScrollPosition,
    tableRef,
    updateLogViewState,
    effectiveTimerange,
    finishedScrollPositionUpdateRef,
    pageRefs,
    onLoadMessages,
  ]);

  return (
    <ListStateContext.Provider value={listStateContextValue}>
      {children}
    </ListStateContext.Provider>
  );
};

export default ListStateProvider;
