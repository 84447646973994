import React, { useCallback } from 'react';

import DataWarehouseDetailsPageSubactions from 'data-warehouse/components/DataWarehouseDetailsPageSubactions';
import { Button, MenuItem } from 'components/bootstrap';
import { LinkContainer } from 'components/common/router';
import Routes from 'routing/Routes';
import type { DataWarehouse } from 'data-warehouse/Types';
import { MoreActions } from 'components/common/EntityDataTable';
import { DATA_WAREHOUSE_ROUTES } from 'data-warehouse/Constants';

const useTableElements = () => {
  const entityActions = useCallback((dataWarehouse: DataWarehouse) => (
    <>
      <LinkContainer to={`${Routes.stream_view(dataWarehouse.stream_id)}?segment=destinations`}>
        <Button bsStyle="info" bsSize="xsmall">Data Routing</Button>
      </LinkContainer>
      <MoreActions>
        <MenuItem href={`${DATA_WAREHOUSE_ROUTES.SEARCH}?streams=${dataWarehouse.stream_id}`}>
          Search in warehouse
        </MenuItem>
        <DataWarehouseDetailsPageSubactions dataWarehouse={dataWarehouse} buttonType="menu" />
      </MoreActions>
    </>
  ), []);

  return {
    entityActions,
  };
};

export default useTableElements;
