import * as React from 'react';
import { useCallback, useContext } from 'react';

import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import type { BackendMessage } from 'views/components/messagelist/Types';
import type { FieldTypeMappingsList } from 'views/logic/fieldtypes/types';
import { updateWidgetConfig } from 'views/logic/slices/widgetActions';
import useAppDispatch from 'stores/useAppDispatch';
import LogViewWidget from 'logview/components/LogViewWidget';
import reexecuteSearchTypes from 'views/components/widgets/reexecuteSearchTypes';
import useOnSearchExecution from 'views/hooks/useOnSearchExecution';
import MessageDetailsContext from 'logview/components/contexts/MessageDetailsContext';
import { fetchMessage } from 'views/hooks/useMessage';
import type { After } from 'logview/types';

import type LogViewWidgetConfig from '../logic/LogViewWidgetConfig';

const ResetListState = ({ resetListState }: { resetListState: () => void }) => {
  const { setActiveMessageDetailsId } = useContext(MessageDetailsContext);

  useOnSearchExecution(() => {
    resetListState();
    setActiveMessageDetailsId(undefined);
  });

  return null;
};

type Props = {
  config: LogViewWidgetConfig,
  data: {
    after: After,
    effectiveTimerange: AbsoluteTimeRange,
    id: string,
    messages: Array<BackendMessage>,
    total: number,
  },
  editing: boolean,
  id: string,
  queryId: string,
  setLoadingState: (loading: boolean) => void,
  fields: FieldTypeMappingsList,
};

const ViewsLogViewWidget = ({ config, data, queryId, id, setLoadingState, editing, fields }: Props) => {
  const dispatch = useAppDispatch();
  const onChangeConfig = useCallback((newConfig: LogViewWidgetConfig) => dispatch(updateWidgetConfig(id, newConfig)), [dispatch, id]);
  const onLoadMessages = useCallback(async (after: After, effectiveTimeRange: AbsoluteTimeRange) => {
    const result = await dispatch(reexecuteSearchTypes({ [data.id]: { after } }, effectiveTimeRange));
    const searchTypeResult = result.payload.result.results[queryId].searchTypes[data.id] as { messages: any, after: After };

    return { messages: searchTypeResult.messages, after: searchTypeResult.after };
  }, [data.id, dispatch, queryId]);

  const onLoadMessage = useCallback((message: { index: string, message: { _id: string }}) => fetchMessage(message.index, message.message._id), []);

  return (
    <LogViewWidget config={config}
                   onLoadMessage={onLoadMessage}
                   effectiveTimerange={data.effectiveTimerange}
                   messages={data.messages}
                   after={data.after}
                   total={data.total}
                   onLoadMessages={onLoadMessages}
                   editing={editing}
                   setLoadingState={setLoadingState}
                   fields={fields}
                   onChangeConfig={onChangeConfig}>
      {({ resetListState }) => <ResetListState resetListState={resetListState} />}
    </LogViewWidget>
  );
};

export default ViewsLogViewWidget;
