import * as React from 'react';
import { useMemo, useCallback } from 'react';
import Immutable from 'immutable';

import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import type { BackendMessage, Message } from 'views/components/messagelist/Types';
import type { FieldTypeMappingsList } from 'views/logic/fieldtypes/types';
import LogViewWidget from 'logview/components/LogViewWidget';
import FieldTypesContext from 'views/components/contexts/FieldTypesContext';
import WidgetFrame from 'views/components/widgets/WidgetFrame';
import { WidgetHeader } from 'views/components/widgets';
import WidgetContainer from 'views/components/WidgetContainer';
import type { After } from 'logview/types';

import type LogViewWidgetConfig from '../logic/LogViewWidgetConfig';

type Props = {
  config: LogViewWidgetConfig,
  messages: Array<BackendMessage>,
  effectiveTimerange: AbsoluteTimeRange,
  after: After,
  editing: boolean,
  setLoadingState: (loading: boolean) => void,
  fields: FieldTypeMappingsList,
  onChangeConfig: (newConfig: LogViewWidgetConfig) => Promise<void>
};

const DWLogViewWidget = ({ config, effectiveTimerange, messages, after, setLoadingState, editing, fields, onChangeConfig }: Props) => {
  const fieldTypes = useMemo(() => ({ all: fields ?? Immutable.List(), queryFields: Immutable.Map<string, FieldTypeMappingsList>() }), [fields]);
  const onLoadMessages = useCallback(() => (
    Promise.resolve({ messages: [], after: '' })
  ), []);
  const onLoadMessage = useCallback((message: { message: { [fieldName: string]: unknown, }}) => (
    Promise.resolve({ formatted_fields: message.message, filtered_fields: message.message, fields: message.message } as unknown as Message)
  ), []);

  return (
    <FieldTypesContext.Provider value={fieldTypes}>
      <WidgetContainer className="widgetFrame" isFocused>
        <WidgetFrame widgetId="data-wearhouse-log-view-widget">
          <WidgetHeader title="Log view"
                        hideDragHandle
                        loading={false}
                        editing={editing}
                        onRename={() => {}} />
          <LogViewWidget config={config}
                         effectiveTimerange={effectiveTimerange}
                         after={after}
                         enableMessageDetails={false}
                         total={10000}
                         messages={messages}
                         onLoadMessages={onLoadMessages}
                         editing={editing}
                         onLoadMessage={onLoadMessage}
                         setLoadingState={setLoadingState}
                         fields={fields}
                         onChangeConfig={onChangeConfig} />
        </WidgetFrame>
      </WidgetContainer>
    </FieldTypesContext.Provider>
  );
};

export default DWLogViewWidget;
