import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import type { TimeRange } from 'views/logic/queries/Query';
import type FetchError from 'logic/errors/FetchError';

const url = (streamId: string) => qualifyUrl(`/plugins/org.graylog.plugins.datawarehouse/data_warehouse/query/${streamId}?limit=100`);

const fetchSearchResults = (fields: Array<string>, streamId: string, timerange: TimeRange, queryString: string) => fetch('POST', url(streamId), {
  timerange,
  fields,
  query: queryString,
});

const useSearchResults = (
  searchFilters: { stream: string, timerange: TimeRange, queryString: string },
  fields: Array<string>,
  enabled: boolean,
): {
  error: FetchError,
  data: { messages: Array<{ message: { [fieldName: string]: unknown } }> },
  refetch: () => void,
  isFetching: boolean
} => useQuery({
  queryKey: ['data-warehouse', searchFilters],
  queryFn: () => fetchSearchResults(fields, searchFilters.stream, searchFilters.timerange, searchFilters.queryString),
  keepPreviousData: true,
  retry: 0,
  enabled,
});

export default useSearchResults;
